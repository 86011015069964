ndhtml {
  font-size: 16px;
}
body {
  font-family: 'Sarabun', sans-serif !important;
}

.material-icons,
.material-icons-outlined {
  font-family: 'Material Icons' !important;
}

.MuiInputBase-input,
.MuiInput-input {
  font-family: 'Sarabun', sans-serif !important;
  font-size: 16px !important;
}
