@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply inline-block p-2 border border-solid rounded-md;
}
.btn-modal {
  @apply inline-flex justify-center w-full px-4 py-2 text-base font-semibold border rounded-md shadow-sm sm:ml-3 sm:w-auto sm:text-sm;
}
.btn-light {
  @apply text-[#0080ef] bg-gray-100 border-gray-100;
}
.btn-primary {
  @apply text-white bg-[#0080ef] border-[#0080ef];
}
.btn-primary.disabled {
  @apply text-black bg-[#a9a9a9] border-[#a9a9a9];
}
.btn-outline-primary {
  @apply text-[#0080ef] bg-transparent border-[#0080ef];
}
.btn-alert-primary {
  @apply inline-block p-2 text-left text-[#0080ef] border-2 border-[#0080ef] border-solid rounded-lg bg-blue-50/25;
}
.btn-alert-mute {
  @apply inline-block p-2 text-left text-gray-800 bg-gray-200 border-2 border-gray-500 border-solid rounded-lg;
}
.btn-mute {
  @apply text-white bg-gray-500 border-transparent;
}
.btn-outline-mute {
  @apply text-gray-700 bg-white border-gray-300;
}
.btn-danger {
  @apply text-white bg-red-500 border border-transparent;
}
.btn-outline-danger {
  @apply text-[#E95D5D] border bg-transparent border-[#E95D5D];
}
.btn-pill {
  @apply text-black bg-blue-200 rounded-full;
}


.block {
  @apply w-full;
}

.disable {
  @apply opacity-50;
}

.form-control {
  @apply px-2 my-2 bg-gray-100 rounded-md shadow-sm sm:text-sm;
}

.card {
  @apply flex flex-col mt-2 bg-white;
}
.card-title {
  @apply px-4 py-5 text-lg font-semibold leading-6 text-gray-900;
}
.text-alert {
  @apply pb-4 text-sm font-semibold text-[#E95D5D];
}
.text-danger {
  @apply font-semibold text-[#E95D5D];
}
.text-nowrap {
  white-space: nowrap;
}
.text-primary {
  @apply text-[#0080ef];
}

.bg-powderblue {
  @apply bg-[#d8efff];
}
 
/********** Menu Bar Template ***********/
.bottom-menu-bar {
  display: flex;
  font-size: xx-small;
  font-weight: bold;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  background: #dadadb;
  color: Black;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottom-menu-item {
  cursor: pointer;
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center; 
  align-items: center; 
  gap: 2px;
  flex: 1;
}

.bottom-menu-item .icon {
  height: 20px;
  width: 20px;
  display: inline-block;
}

.no-wrap {
  white-space: nowrap;
}